<template>
  <div>
    <vs-row vs-type="flex">
      <vs-col
        vs-type="flex"
        vs-justify="start"
        vs-align="right"
        vs-w="7"
        class="mb-3"
      >
        <h4 class="mt-3 mr-3">
          اسم البرنامج :
        </h4>
        <vs-card
          style="background-color: white; width:250px; height: 40px; border-radius:7px"
        >
          <vs-row>
            <h5 style="margin-left: auto; margin-right: auto">
              {{ program.name }}
            </h5>
          </vs-row>
        </vs-card>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="end"
        vs-align="left"
        vs-w="5"
        class="mb-3"
      >
        <h4 class="mt-3 mr-3">
          خيارات البرنامج :
        </h4>
        <vs-card style="background-color: white; height: 40px; width:200px; border-radius:7px">
          <vs-row>
            <div>
              <feather-icon
                icon="EyeIcon"
                style="color:#3A273E"
                @click="popupActiveDetails = true"
                class="ml-2 mr-5 text-success button_icon pointer"
              />
            </div>
            <div v-can="permissions.update">
              <vs-switch
                color="success"
                v-model="checked"
                @input="closeProgram"
                class="mr-2 pointer"
              />
            </div>
            <div v-can="permissions.update">
              <feather-icon
                icon="EditIcon"
                @click="editProgram"
                style="color:#3A273E"
                class="ml-2 mr-5 button_icon pointer"
              />
            </div>
            <div v-can="permissions.delete">
              <feather-icon
                icon="Trash2Icon"
                @click="confirmDeleteRecord"
                svg-classes="text-danger"
                class=" mr-2 button_icon pointer"
              />
            </div>
          </vs-row>
        </vs-card>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="12"
        class="mb-5"
      >
        <vs-card>
          <vs-tabs
            alignment="fixed"
            color="primary"
            v-model="index"
          >
            <vs-tab
              v-for="route in routes"
              :key="route.id"
              :label="route.label"
              v-can="route.permission"
              icon-pack="feather"
              :icon="route.icon"
              v-show="checkPermission(route.permission)"
              @click="redirect(route.name)"
            />
          </vs-tabs>
          <router-view />
        </vs-card>
      </vs-col>
    </vs-row>
    <update-popup
      :program="program"
      title="تعديل برنامج"
      @updated="programUpdated"
      ref="updatePopupRef"
    />
    <vs-popup
      title="تفاصيل البرنامج"
      :active.sync="popupActiveDetails"
      :button-close-hidden="true"
    >
      <div
        v-for="(image,index) in program.images"
        :key="index"
      >
        <img
          :src="image"
          alt="content-img"
          class="responsive rounded-lg mb-3"
          style="height: 20em;object-fit: cover;"
        >
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-6 px-4 mt-2">
        <div v-if="program.previousActivities.length > 0">
          <label> الأنشطة والأعمال السابقة:</label>
          <div
            v-for="(item,index) in program.previousActivities"
            :key="index"
          >
            <div>
              <vs-input
                placeholder=""
                name="title"
                disabled
                color="#5E445A"
                class="w-full mt-3"
                v-model="program.previousActivities[index]"
              />
            </div>
          </div>
        </div>
        <div>
          <label>الاسم :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="program.name"
          />
        </div>

        <div>
          <label>الحالة :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="program.status"
          />
        </div>
        <div>
          <label>الموقع :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="program.location"
          />
        </div>
        <div v-if="program.beneficiariesNumber">
          <label>عدد المستفيدين :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="program.beneficiariesNumber"
          />
        </div>
        <div>
          <label>المبلغ الكلي :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="program.targetAmount"
          />
        </div>
        <div>
          <label>المبلغ المجموع :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="program.collectedAmount"
          />
        </div>
        <div>
          <label>الترتيب في الموقع :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="program.order"
          />
        </div>
        <div>
          <label>تاريخ الانتهاء :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="program.finishedAt"
          />
        </div>
        <div v-if="program.campaignsCount">
          <label>عدد الحملات :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="program.campaignsCount"
          />
        </div>
        <div v-if="program.emergenciesCount">
          <label>عدد الحالات الطارئة :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="program.emergenciesCount"
          />
        </div>
        <div>
          <label> تاريخ الإضافة :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="program.createdAt"
          />
        </div>
        <div>
          <label> تاريخ التعديل :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="program.updatedAt"
          />
        </div>
        <div
          class="col-span-2"
        >
          <label>الوصف :</label>
          <vs-textarea
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="program.description"
          />
        </div>
      </div>

      <div class="flex justify-end items-center mt-8 px-4">
        <vs-button
          @click="popupActiveDetails=false"
          type="filled"
          ycolor="success"
        >
          حسناً
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import UpdatePopup from './programPopup.vue';
import {RepositoryFactory} from "../../donations-repositories/donationsRepositoryFactory";
import utilities from "../../../shared/utilities";

const programsRepo = RepositoryFactory.get("programsRepository");

export default {
  name: "ProgramDetails",
  components: {
    UpdatePopup
  },
  data() {
    return {
      checked: true,
      program: {
        collectedAmount: '',
        id: null,
        name: '',
        description: '',
        location: '',
        status: '',
        beneficiariesNumber: '',
        targetAmount: '',
        finishedAt: '',
        images: [],
        order: '',
        programHistoryIds: [],
        previousActivities: [],
        createdAt: '',
        updatedAt: '',
        campaignsCount: 0,
        emergenciesCount: 0
      },
      popupActiveDetails: false,
      routes: [
        {
          name: 'websiteDonations',
          index: 0,
          label: 'التبرع العام',
          permission: 'get_donations'
        },
        {
          name: 'campaigns',
          index: 1,
          label: 'الحملات',
          permission: 'get_campaigns'
        },
        {
          name: 'emergencies',
          index: 2,
          label: 'الحالات الطارئة',
          permission: 'get_emergencies'
        }
      ],
      permissions: {
        add: "store_programs",
        update: "update_programs",
        delete: "destory_programs"
      },
      index: 0
    };
  },
  methods: {
    redirect(route) {
      this.$router.push({name: route});
    },
    checkPermission(permission) {
      return utilities.checkPermission(permission);
    },
    confirmDeleteRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'هل أنت متأكد من الحذف؟',
        text: `في حال الموافقة, لن تتمكن من التراجع!`,
        accept: this.deleteProgram,
        acceptText: 'موافق'
      });
    },
    async programUpdated() {
      this.program = await programsRepo.getProgramById(this.$route.params.id);
      this.checked = this.program.status === 'OPENED';
    },
    editProgram() {
      this.$refs.updatePopupRef.open();
    },
    deleteProgram() {
      programsRepo.deleteProgram(this.$route.params.id);
      this.$router.push({path: '/donations/programs'});
    },
    closeProgram() {
      if (this.checked)
        this.program.status = 'OPENED';
      else
        this.program.status = 'CLOSED';
      programsRepo.updateProgram(this.program);
    },
  },
  async created() {
    let route = this.routes.find((r) => {
      return r.name === this.$route.name;
    });

    if (route !== undefined)
      this.index = route.index;
    else
      this.redirect(this.routes[0].name);
    this.program = await programsRepo.getProgramById(this.$route.params.id);
    console.log(this.program.previousActivities.length);
    this.checked = this.program.status === 'OPENED';
  }
};
</script>

<style>
.pointer {
  cursor: pointer;
}

.vs-textarea {
  height: 100px
}
</style>
